import React from "react";
import { Card, CardBody, Container, Row, Col } from "reactstrap";
import GeneralDonation from "components/GeneralDonation";
import NormalDonation from "components/NormalDonation";
import { TabView, TabPanel } from 'primereact/tabview';
import Remotes from "Remotes";
import { ProgressSpinner } from "primereact/progressspinner";
import { useDispatch } from "react-redux";
import { fetchAddBasket } from "store/store/actions/basket";
import { getBasketStorage } from "store/store/actions/basket";
import { useLocation } from "react-router";
// import video from "../assets/videos/1.mp4";
// import video2 from "../assets/videos/1.webm";
import sliderMobile from "../assets/img/slider-mobil.webp";
import slider from "../assets/img/slider-kuyu.webp";
import useMediaQuery from "components/useMediaQuery";


function useQuery() {
  const { search } = useLocation();
  let a =React.useMemo(() => new URLSearchParams(search), [search]);
  return a;
}

function Donations() {
    const [selectedTab, setSelectedTab] = React.useState(0);
    const [searchDonation, setSearchDonation] = React.useState();
    const [filteredDonation, setFilteredDonation] = React.useState();
    const [donationData, setDonationData] = React.useState([]);
    const [loading, setLoading] = React.useState(true);

    const isDesktop = useMediaQuery('(min-width: 960px)');

    let query = useQuery();
    const dispatch = useDispatch();

    React.useEffect(()=>{
      Remotes.getDonationType().then( r => {
        setDonationData(r.data)
      });
    if(query.get('title')) {
      dispatch({type: "BASKET_REMOVE"})
      query.getAll('title').forEach((item2, ind) => {
        let localSt  = JSON.parse(localStorage.getItem("sepet"));
        let control = false;
        let newData = localSt?.map(item=> {
          if(item.title == query.getAll('title')[ind] && item.isHisse === true){
            item.piece = item.piece + 1;
            item.hisseInfo.push({nameSurname: null, phone: null, mail: null,nameError: false, phoneError: false, mailError: false})
            control = true;
            //setIsVekaletShow(true);
          }
          return item;
        })
        if(control){
          localStorage.setItem('sepet', JSON.stringify(newData) )
        }
        else {
          let donation = { 
            DonationTypeID: parseInt(query.getAll('DonationTypeID')[ind]), 
            title: query.getAll('title')[ind], 
            refCode: null , 
            description: null, 
            type: parseInt(query.getAll('type')[ind]), 
            amount: parseFloat(query.getAll('amount')[ind]), 
            otherCheck: query.getAll('isHisse')[ind] === 'true', 
            piece: 1,  
            name: '', 
            phone: '', 
            mail: '', 
            otherName: '', 
            otherPhone: '', 
            otherMail: '',  
            otherPhoneError: false, 
            otherMailError: false, 
            otherNameError: false, 
            isVekalet: query.getAll('isVekalet')[ind] === 'true', 
            isHisse: query.getAll('isHisse')[ind] === 'true', 
            hisseInfo: [{nameSurname: null, phone: null, mail: null,nameError: false, phoneError: false, mailError: false}] 
          };
          dispatch(fetchAddBasket(donation, donation.amount));
        }
      })
      dispatch(getBasketStorage());
    }
    }, [])

    // const groupBy =(list, keyGetter)  => {
    //   const map = new Map();
    //   list.forEach((item) => {
    //        const key = keyGetter(item);
    //        const collection = map.get(key);
    //        if (!collection) {
    //            map.set(key, [item]);
    //        } else {
    //            collection.push(item);
    //        }
    //   });
    //   return Array.from(map, ([name, value]) => ({ name, value }));;
    // }

    // type 1: Genel Bağış, 2: Normal Bağış

    const tabHeaderITemplate = (options, id) => {
      return (
          <button  type="button" onClick={options.onClick} className={options.className}>
              {/* <img src={options.selected ? heartSelectedSvg : heartSvg} alt="tab" /> */}
              <img src={process.env.REACT_APP_BASE_URL + process.env.REACT_APP_GET_CATEGORY_IMAGE + id} style={options.selected ? null : {filter: 'brightness(0) invert(1)'} } alt="tab" />
              {options.titleElement}
          </button>
      );
    };

    const searchDonate = (event) => {
      setTimeout(() => {
          let _filteredDonation;
          if (!event.query.trim().length) {
            _filteredDonation = [...searchDonation];
          }
          else {
            _filteredDonation = searchDonation.filter((donate) => {
                  return donate.name.toLowerCase().startsWith(event.query.toLowerCase());
              });
          }

          setFilteredDonation(_filteredDonation);
      }, 250);
  }

    return (
        <section >
          <div className="donation-search-area">
            {/* <div className="donation-image" /> */}
            <img src={isDesktop ? slider : sliderMobile} alt="slider" />
            {/* <video  width="100%" height="100%" autoPlay loop muted preload="auto" playsInline  >
                <source src={video} type="video/mp4" />
                <source src={video2} type="video/webm" />
                Your browser does not support the video tag.
            </video> */}
             {/* <div className="donation-text"> */}
              {/* <p className="donation-title">"İyiliğin izi kalıcı olur"</p>
              <p className="donation-desc">Desteklerinizle iyiliği büyütüyor, ihtiyaç sahibi coğrafyalarda iz bırakıyoruz!</p> */}
              {/* <span className="p-input-icon-right donation-input-group">
                  <BiSearchAlt2 fontSize={22}/>
                  <AutoComplete className=" w-100" placeholder="Projelerde ara!" suggestions={filteredDonation} completeMethod={searchDonate} value={searchDonation} onChange={(e) => setSearchDonation(e.value)} />
              </span> */}
              {/* <AutoComplete className="donation-input-group" placeholder="Projelerde ara!" suggestions={filteredDonation} completeMethod={searchDonate} value={searchDonation} onChange={(e) => setSearchDonation(e.value)} /> */}

            {/* </div>  */}
          </div>
          <div className="donation-tabs">
            <Card>
              <CardBody>
                <TabView activeIndex={selectedTab} scrollable onTabChange={(e) => setSelectedTab(e.index)}  >
                  {
                    donationData && donationData.map((item, index) => {
                      return <TabPanel header={item.CategoryTitle} headerTemplate={(options) => tabHeaderITemplate(options, item.List[0].CategoryID )} key={"tabpanel_"+index}>
                        <Container>
                          <Row>
                            {
                              item.List.map((val, ind) => {
                                if(donationData.length === (index +1) && item.List.length === (ind +1) && loading)  {
                                  setLoading(false)
                                }
                                return val.DonationKindID === 1 ? <Col xs="12" md="6" lg="6" className="donation_col" key={"tabCol_" + ind}><GeneralDonation key={"general" + ind} donationTypeID={val.ID} title={val.DonationTitle} description={val.DonationDescription}  buttonNames={val.GenericDonationTypeViewModel} isVekalet={val.IsVekalet} isHisse={val.IsHisse}  /></Col>
                                : <Col xs="12" md="6" lg="6" className="donation_col" key={"tabCol_" + ind}><NormalDonation key={"normal_" + ind} title={val.DonationTitle} donationTypeID={val.ID} description={val.DonationDescription}  amount={val.Amount} isVekalet={val.IsVekalet} isHisse={val.IsHisse} /></Col>
                              })
                            }
                          </Row>
                        </Container>
                      </TabPanel>
                    })
                  }
                </TabView>
                {
                    loading ?
                    <div style={{margin: "auto", width: 100, height: 100}} className="d-flex flex-column align-items-center">
                      <ProgressSpinner style={{width: '50px', height: '50px'}} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s"/>
                      <p className="text-center" style={{width: 150}}>Bağışlar Yükleniyor</p>
                    </div> : null
                  }
              </CardBody>
            </Card>
          </div>
        </section>
      );
}
export default Donations;
